<template>
  <div>
    <v-app-bar
      :height="$vuetify.breakpoint.width <= 710 ? '150' : '56'"
      class="header__wrapper"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-5'"
    >
      <v-row align="center" class="d-flex justify-start">
        <v-col cols="auto" class="px-0 py-0">
          <v-btn icon @click="toggleLeftMenu(!mini)" size="24"
            ><v-icon size="24">mdi-menu</v-icon></v-btn
          >
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <img
            @click="$router.push('/').catch(() => {})"
            class="cursor-pointer"
            src="@/assets/layout_img/TaskTrackerLogoName.svg"
          />
        </v-col>
        <v-menu offset-y v-model="showProjects" :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined color="orange" class="ml-5" v-bind="attrs" v-on="on" @click="toggleProjects">
              <div v-if="$vuetify.breakpoint.width >= 780">{{ $t("form.Project_lable") }}</div>
              <v-icon v-else>mdi-list-box</v-icon>
            </v-btn>
          </template>
          <v-card style="min-width: 300px; max-height: 300px; overflow-y: auto;">
            <v-list>
              <h4 style="font-size: 18px;" class="ml-4 py-3">{{ $t('home.created_by_me') }}</h4>
              <v-list-item
                v-for="(project, index) in userProjects"
                :key="project.id"
                @click="pushToProject(project)">
                <v-list-item-avatar>
                  <v-img v-if="project.project_media" :src="project.project_media" alt="project image" />
                  <v-avatar v-else size="40" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(project.name) }}</v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ project.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-spacer />
                  <v-list-item-subtitle class="justify-end d-flex mr-5">
                    <div v-if="project.role === 1">
                      <v-icon>mdi-account-cowboy-hat</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.owner") }}
                      </span>
                    </div>
                    <!-- <div v-else-if="project.role === 2">
                      <v-icon>mdi-account-star</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.project_manager") }}
                      </span>
                    </div>
                    <div v-else-if="project.role === 3">
                      <v-icon>mdi-account</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.staff") }}
                      </span>
                    </div> -->
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>
            
              <h4 style="font-size: 18px;" class="ml-4 py-3">{{ $t('home.guest_projects') }}</h4>
              <v-list-item
                v-for="(project, index) in guestProjects"
                :key="project.id"
                @click="pushToProject(project)">
                <v-list-item-avatar>
                  <v-img v-if="project.project_media" :src="project.project_media" alt="project image" />
                  <v-avatar v-else size="40" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(project.name) }}</v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ project.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-spacer />
                  <v-list-item-subtitle class="justify-end d-flex mr-5">
                    <!-- <div v-if="project.role === 1">
                      <v-icon>mdi-account-cowboy-hat</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.owner") }}
                      </span>
                    </div> -->
                    <div v-if="project.role === 2">
                      <v-icon>mdi-account-star</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.project_manager") }}
                      </span>
                    </div>
                    <div v-else-if="project.role === 3">
                      <v-icon>mdi-account</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.staff") }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-btn outlined color="orange" small class="ml-3" @click="statisticsTime = true;">
          <v-icon left v-if="$vuetify.breakpoint.width >= 953">mdi-timeline-text</v-icon>
          <v-icon v-else>mdi-timeline-text</v-icon>
          <div v-if="$vuetify.breakpoint.width >= 953">{{ $t("btn.timeReport") }}</div>
        </v-btn>

        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                outlined
                v-bind="attrs"
                v-on="on"
                elevation="0"
                :ripple="false"
                color="orange"
                :style="$vuetify.breakpoint.width >= 953 ? '':'max-width: 60px; min-width: 60px;'"
              >
                <span class="d-flex align-center">
                  <v-icon size="18">mdi-check-circle-outline</v-icon>
                  <span class="ml-1 text-subtitle" v-if="$vuetify.breakpoint.width >= 953"
                    >{{ $t("form.Tasks") }}: {{ tasksAmount }}</span
                  >
                  <span v-else class="ml-1 text-subtitle"
                    >{{ tasksAmount }}</span
                  >
                </span>
              </v-btn>
            </template>
            <span> {{ $t("form.Tasks") }}: {{ tasksAmount }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto" class="pl-0"> <Theme /> </v-col>
        <v-col cols="auto" class="px-0 py-0"> <Language /> </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <UserDropdown />
        </v-col>
      </v-row>
    </v-app-bar>
    <navigationDrawer />
    <statisticsTimeDialog
      :visible="statisticsTime"
      @close="statisticsTime = false"
      v-if="statisticsTime"
    />
      <!-- :project="statisticsTime" -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import * as mutationTypes from "@/store/modules/auth/types/mutations";
import * as actionTypes from "@/store/modules/sideBarValues/types/actions";
import * as actionType from "@/store/modules/auth/types/actions";
import store from "@/store";

import UserDropdown from "@/components/layout/UserDropdown.vue";
import NavigationDrawer from "@/components/layout/navigationDrawer.vue";
import Theme from "@/components/layout/Theme.vue";
import Language from "@/components/layout/Language.vue";
import usersService from "@/services/request/users/usersService";
import projectsService from "@/services/request/projects/projectsService";
import statisticsTimeDialog from "../home/statisticsTimeDialog.vue";

export default {
  name: "HeaderComponent",
  components: { NavigationDrawer, UserDropdown, Theme, Language, projectsService, statisticsTimeDialog },
  data: () => ({
    tasksAmount: 0,
    userProjects: [],
    guestProjects: [],
    showProjects: false,
    statisticsTime: false,
  }),
  mounted() {
    this.fetchCardsAmount();
  },
  methods: {
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
    async MyProjects() {
      try {
        const params = {
          page: this.page_MyProject,
          per_page: 9999,
          owner: true,
        };
        const newMyProjects = await projectsService.getList(params);
        if (newMyProjects.length) {
          this.userProjects = [...newMyProjects];
        } else {
          this.userProjects = [];
        }
        //console.log("All Projects: ", newMyProjects);
      } catch (e) {
        console.log(e);
      }
    },
    async GuestProjects() {
      try {
        const params = {
          page: this.page_MyProject,
          per_page: 9999,
          owner: false,
        };
        const newMyProjects = await projectsService.getList(params);
        if (newMyProjects.length) {
          this.guestProjects = [...newMyProjects];
        } else {
          this.guestProjects = [];
        }
        //console.log("All Projects: ", newMyProjects);
      } catch (e) {
        console.log(e);
      }
    },
    toggleProjects() {
      if (!this.showProjects) {
        this.MyProjects();
        this.GuestProjects();
      }
      this.showProjects = !this.showProjects;
    },
    async pushToProject(project) {
      this.$router.push('/').catch(() => {});
      console.log('Обраний проєкт:', project);
      store.dispatch(
        `sideBarValues/${actionTypes.SET_COPIED_UUID}`,
        project.id,
      );
      store.dispatch(
        `sideBarValues/${actionTypes.SET_COPIED_PROJECT}`,
        project,
      );

      const userData = this.getUserData;
      userData.role = project.role;

      await this.updateUserData(userData);

      this.$router.push(
        this.$localize({
          name: "projectInfo",
          params: { id: project.id, name: project.name },
        })
      );
    },
    toggleLeftMenu(param) {
      this.$store.commit(`auth/${mutationTypes.SET_MENU_POSITION}`, param);
    },
    async fetchCardsAmount() {
      try {
        await this.getUserCards();
        const interval = 60000;
        this.intervalFetchUserData = setInterval(() => {
          this.getUserCards();
        }, interval);
      } catch (e) {
        console.log(e);
      }
    },
    async getUserCards() {
      const userCards = await usersService.getUserCards();
      this.tasksAmount = userCards.length;
    },
    ...mapActions("auth", {
        updateUserData: actionType.USER_UPDATE_DATA,
      }),
  },
  destroyed() {
    clearInterval(this.intervalFetchUserData);
  },
  computed: {
    ...mapGetters("auth", {
      getUserData: getterTypes.GET_USER_DATA,
    }),
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION,
    }),

    mini: {
      get() {
        return this.menuPosition;
      },
      set(mini) {
        return mini;
      },
    },
  },
};
</script>

<style scoped>
.header__wrapper {
  position: relative;
  width: 100%;
}
</style>
