// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = "/projects";

export default {
  async getUsersManaged() {
    const response = await requestService.get(`/managed-colleagues`);
    return response?.data?.data;
  },
  async getAllUsers(params) {
    const response = await requestService.get(`/time-records/total-time-for-managed-colleagues`, params);
    return response?.data?.data;
  },
  async getList(params = {}) {
    const response = await requestService.get(`${prefix}`, params);
    return response?.data?.data;
  },
  async getStatisticBoard(projectId, boardId, params = {}) {
    const response = await requestService.get(`${prefix}/${projectId}/boards/${boardId}/statistic`, params);
    //console.log("BoardStatistic response :", response?.data?.data);
    return response?.data?.data;
  },
  async getStatisticBoardUser(projectId, boardId, params = {}) {
    const response = await requestService.get(`${prefix}/${projectId}/boards/${boardId}/statistic`, params);
    //console.log("BoardStatistic response :", response?.data?.data);
    return response?.data?.data;
  },
  async getListLastPage(params = {}) {
    const response = await requestService.get(`${prefix}`, params);
    return response?.data;
  },
  async addProject(payload) {
    const response = await requestService.post(`${prefix}`, payload);
    return response?.data?.data;
  },
  async updateProject(uuid, params) {
    const response = await requestService.post(
      `${prefix}/${uuid}/update`,
      params
    );
    return response?.data;
  },
  async deleteProject(uuid) {
    const response = await requestService.delete(`${prefix}/${uuid}`);
    return response?.data;
  },
  async addUserToProject(uuid, payload) {
    const response = await requestService.post(
      `${prefix}/${uuid}/add-users`,
      payload,
      {},
      false
    );
    return response?.data;
  },
  // Users
  async getUsers(uuid) {
    const response = await requestService.get(`${prefix}/${uuid}/users`);
    return response?.data?.users;
  },
  async deleteUser(uuid, payload) {
    const response = await requestService.post(
      `${prefix}/${uuid}/remove-users`,
      payload
    );
    return response?.data;
  },
  async changeUser(uuid, payload) {
    const response = await requestService.post(
      `${prefix}/${uuid}/change-role`,
      payload
    );
    return response?.data;
  },
  async getUserTimeStatistics(params) {
    const response = await requestService.get(
      `/time-records/user-across-projects`,
      { ...params }
    );
    return response?.data?.data;
  },
  async getUsersTime(uuid, params) {
    const response = await requestService.get(
      `${prefix}/${uuid}/time-records/project`,
      { ...params }
    );
    return response?.data?.data;
  },
  // ActivityProjectDialog
  async getActivityProject(project) {
    const response = await requestService.get(
      `/projects/${project}/activities`
    );
    return response?.data?.data;
  },
  async addActivity(project, payload) {
    const response = await requestService.post(
      `/projects/${project}/activities`,
      payload, {}, false
    );
    return response?.data?.data;
  },
  async updateActivity(project, activity, payload) {
    const response = await requestService.patch(
      `/projects/${project}/activities/${activity}`,
      payload
    );
    return response?.data?.data;
  },
  async deleteActivity(project, activity) {
    const response = await requestService.delete(
      `/projects/${project}/activities/${activity}`
    );
    return response?.data;
  },
  // ActivityProjectDialog
  // Links
  async getLinks(projectId) {
    const response = await requestService.get(
      `${prefix}/${projectId}/project-links`
    );
    return response?.data?.data;
  },
  async addLink(projectId, payload) {
    const response = await requestService.post(
      `${prefix}/${projectId}/project-links`,
      payload, {}, false
    );
    return response?.data?.data;
  },
  async deleteLink(projectId, linkId) {
    const response = await requestService.delete(
      `${prefix}/${projectId}/project-links/${linkId}`
    );
    return response?.data;
  },
  async updateLink(projectId, linkId, payload) {
    const response = await requestService.patch(
      `${prefix}/${projectId}/project-links/${linkId}`,
      payload
    );
    return response?.data?.data;
  },
  // Labels
  async getLabels(projectUuid, boardUuid) {
    const response = await requestService.get(
        `${prefix}/${projectUuid}/labels`
    );
    return response?.data?.labels;
  },
  async addLabel(projectUuid, payload) {
    const response = await requestService.post(
        `${prefix}/${projectUuid}/labels`,
        payload
    );
    return response?.data?.label;
  },
  async updateLabel(projectUuid, labelUuid, payload) {
    const response = await requestService.patch(
        `${prefix}/${projectUuid}/labels/${labelUuid}`,
        payload
    );
    return response?.data?.label;
  },
  async deleteLabel(projectUuid, labelUuid) {
    const response = await requestService.delete(
        `${prefix}/${projectUuid}/labels/${labelUuid}`
    );
    return response?.data?.label;
  },
  // Parent cards
  async getParentCards(projectUuid, params) {
    const response = await requestService.get(`${prefix}/${projectUuid}/parent-cards`, params);
    return response?.data?.data;
  },
  async postParentCards(projectUuid, boardUuid, listUuid, payload) {
    const response = await requestService.post(
      `${prefix}/${projectUuid}/boards/${boardUuid}/lists/${listUuid}/cards`,
      payload
    );
    return response?.data?.card;
  },
  async updateParentCards(projectUuid, cardUuid, payload) {
    const response = await requestService.post(
      `${prefix}/${projectUuid}/cards/${cardUuid}`,
      payload
    );
    return response?.data?.card;
  },
};
