// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = "/users";

export default {
  async updateAccount(uuid, payload) {
    const response = await requestService.post(`/profile/${uuid}`, payload);
    return response?.data?.user;
  },
  async deleteUser(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
  async addUser(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/add`, payload, {}, false);
  },
  async updateUser(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/update`, payload);
  },
  async getUserCards() {
    const response = await requestService.get(`/user/active-cards`);
    return response?.data?.cards;
  },
};
