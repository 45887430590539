<template>
  <v-navigation-drawer
    fixed
    v-model="miniMob"
    absolute
    :style="{
      top: $vuetify.breakpoint.width <= 710 ? '150px':'56px',
      height: `calc(100vh - 56px)`,
      width: mini ? '237.5px' : '0px',
      'z-index': 100,
    }"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :mini-variant="!mini"
    :border="true"
  >
    <v-divider />
    <v-list nav dense>
      <v-list-item-group
        active-class="active-orange--text"
        color="orange"
      >
        <div v-for="(list, key) in listData" :key="key">
          <div v-if="'nested_data' in list">
            <v-list-group
              color="orange"
              class="listGroupMenu"
              active-class="active-orange--text"
              v-if="list.permission"
              :value="list.value"
              @click="navigateTo(list.navigate_name)"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ list.activator_data.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  list.activator_data.title
                }}</v-list-item-title>
              </template>

              <div v-for="(nested_data, key) in list.nested_data" :key="key">
                <v-list-item
                  active-class="active-orange--text"
                  @click="pushToSide(nested_data)"
                >
                  <VListItemIcon
                    ><VIcon>{{ nested_data.icon }}</VIcon></VListItemIcon
                  >
                  <v-list-item-title>{{ nested_data.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
          <div v-else>
            <v-list-item
              active-class="active-orange--text"
              :class="list.class"
              :to="$localize({ name: list.navigate_name })"
              exact
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <v-icon>{{ list.icon }}</v-icon>
                  </v-list-item-icon>
                </template>
                {{ list.title }}
              </v-tooltip>
              <v-list-item-title> {{ list.title }} </v-list-item-title>
            </v-list-item>
          </div>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import * as mutations from "@/store/modules/auth/types/mutations";
import * as getterTypes from "@/store/modules/auth/types/getters";
import navigationDrawer from "@/components/layout/navigationDrawer";
import lang from "@/mixins/language";
import * as getterType from "@/store/modules/sideBarValues/types/getters";
export default {
  name: "NavigationDrawer",
  mixins: [navigationDrawer, lang],
  data: () => ({
    mini: false,
    miniMob: false,
    listData: [],
    projectsSelector: false,
    boardSelector: false,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    pushToSide(nested_data) {
      if (!nested_data.navigate_name) return;
      this.$router.push({ name: nested_data.navigate_name }).catch(() => {});
    },
    fetchData() {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.mini = true;
        this.miniMob = this.menuPosition;
      } else {
        this.mini = this.menuPosition;
        this.miniMob = false;
      }
      this.renderMenu();
    },
    navigateTo(routeName) {
      console.log(routeName);
      if (!routeName) return;
      this.$router.push({ name: routeName }).catch(() => {});
    },
  },
  computed: {
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION,
    }),
    ...mapGetters("sideBarValues", {
      copyListData: getterType.GET_COPIED_DATA,
    }),
    ...mapGetters("sideBarValues", {
      copyUuid: getterType.GET_COPIED_UUID,
    }),
    ...mapGetters("sideBarValues", {
      copyUuidBoard: getterType.GET_COPIED_UUID_BOARD,
    }),
    ...mapGetters(["currentLanguage"]),
  },
  watch: {
    copyListData: {
      handler(e) {
        if (e) {
          this.listData = e;
        }
      },
    },
    miniMob(e) {
      this.$store.commit(`auth/${mutations.SET_MENU_POSITION}`, e);
      this.renderMenu();
    },
    currentLanguage: {
      handler() {
        this.renderMenu();
      },
    },
    menuPosition: "fetchData",
  },
};
</script>

<style lang="scss"></style>